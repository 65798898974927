/* global siteSettings */

import { getCLS, getFID, getLCP } from 'web-vitals';

// IMPORTANT! Bump this if you change something and describe your changes in
// the README in this folder
const version = 0.9;

const botUARegexps = [
  /Mediapartners\-Google/,
  /AdsBot\-Google/,
  /Googlebot/,
  /FeedFetcher\-Google/,
  /BingBot/,
  /BingPreview/,
  /Baiduspide/,
  /Slurp/,
  /Sogou/,
  /facebook/,
  /ia_archive/,
  /bots?[\/\s\)\;]/,
  /spider[\/\s\)\;]/,
  /Slack/,
  /Calypso AppCrawle/,
  /pingdom/,
  /lyticsbot/,
];
const botsIP = [
  // Googlebot
  // https://www.lifewire.com/what-is-the-ip-address-of-google-818153
  ['64.68.90.1', '64.68.90.255'],
  ['64.233.173.193', '64.233.173.255'],
  ['66.249.64.1', '66.249.79.255'],
  ['216.239.33.96', '216.239.59.128'],
  // Facebook's bot
  ['66.220.144.0', '66.220.159.255'],
  ['69.171.224.0', '69.171.255.255'],
  ['173.252.64.0', '173.252.127.255'],
  ['31.13.64.0', '31.13.127.255'],
];

function checkIpRanges(ip) {
  function ipToNumber(ipAddr) {
    return ipAddr.split('.').reduce(
      (prev, segment, i) => prev + (Number(segment) << 8 * (4 - i - 1)),
      0,
    );
  }
  const ipNumeric = ipToNumber(ip);
  for (let i = 0; i < botsIP.length; i++) {
    const range = botsIP[i];
    if (typeof range === 'string' && ip === range) { return true; }
    const startIp = ipToNumber(range[0]);
    const endIp = ipToNumber(range[1]);
    if (ipNumeric >= startIp && ipNumeric <= endIp) { return true; }
  }
  return false;
}

/**
 * @brief Actually sending the data with GA/XHR to Athena/etc. under the hood.
 */
function send(data) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ...data,
    event: 'GAEvent',
    // Use a non-interaction event to avoid affecting bounce rate.
    nonInteraction: true,
  });
}

/**
 * @brief A function to process and report data returned by the metrics methods
 *
 * @param [in] name - the name of the metric
 * @param [in] value - the value
 * @param [in] id - the ID of an event
 * @param [in] delta - the difference between the current value and the previous
 * @param [in] entries - an array of PerformanceEntry objects
 */
function report({
  name, value, id, delta, isFinal,
}) {
  // Safari and Firefox do support CLS, but in my testing always report zero.
  // This is obviously incorrect and would inevitably lead to false "good"
  // results contributed to the aggregate score. Ignoring those.
  if (value === 0) { return; }

  // We don't want to report non-final version of a metric value to avoid having
  // falsly good results mixed in (especially true for CLS)
  if (!isFinal) { return; }

  // Trying to filter out bots
  if (
    botUARegexps.some((el) => el.test(navigator.userAgent)) ||
    window.siteSettings && siteSettings.userIp &&
      checkIpRanges(siteSettings.userIp)
  ) {
    return;
  }

  let adjustedValue = value;
  switch (name) {
    // Google Analytics metrics must be integers, so the value is rounded.
    // The following values are multiplied by some factor for greater precision
    // (note: increase the multiplier for greater precision if needed).
    case 'CLS':
      adjustedValue = value * 1000;
      break;
    case 'FID':
    case 'LCP':
      adjustedValue = value * 10;
      break;
    default:
      adjustedValue = value;
  }

  send({
    eventCategory: 'Web Vitals',
    // The limit of our custom dimensions is used up, so we have to resort to
    // hacks to add versioning to the data
    eventAction: `${name} [version=${version}]`,
    eventValue: Math.round(adjustedValue),
    // The `id` value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), Google Analytics can
    // compute a total by grouping on this ID (note: requires `eventLabel` to
    // be a dimension in your report)
    eventLabel: id,
  });

  const url =
    `/stats?type=${name}&v=${version}&value=${Math.round(adjustedValue)}` +
    `&value_original=${value}&delta=${delta}&id=${id}`;
  if (navigator.sendBeacon) {
    navigator.sendBeacon(url);
  } else if (window.fetch) {
    fetch(url, { method: 'POST', keepalive: true });
  }
}

// Registering the monitoring for collecting metrics. MUST be done ASAP before
// everything else in order to not miss any PerformanceEntry events
getLCP(report);
getFID(report);
getCLS(report);
